import Vue from "vue";
import router from '@/router'
import axios from 'axios'
axios.defaults.withCredentials = true
import Qs from 'qs';
import md5 from 'js-md5';
let Base64 = require('js-base64').Base64;
import store from '../store'
import { Loading, Message } from 'element-ui' // 引用element-ui的加载和消息提示组件
axios.defaults.withCredentials = true //让ajax携带cookie
axios.defaults.timeout = 100000;
let loading = null;

// 公共配置
if (process.env.NODE_ENV == 'development') {
  // axios.defaults.baseURL = 'http://localhost:8080/';
  axios.defaults.baseURL = '/api';
  Vue.prototype.baseUrl = axios.defaults.baseURL;
} else if (process.env.NODE_ENV == 'production') {
  // axios.defaults.baseURL = '';
  //axios.defaults.baseURL = '//www.saen.com/';
   //axios.defaults.baseURL = 'https://www.saen.com/';
  // axios.defaults.baseURL = '/api';
  // axios.defaults.baseURL = 'http://139.9.141.68/sean';
  // axios.defaults.baseURL = 'http://localhost:8081/api';
}
//请求拦截器
axios.interceptors.request.use(
  config => {
    //loading = Loading.service({text: '拼命加载中'});
    const token = localStorage.getItem("token");
    const hostName = document.domain;
    config.headers.token = '';
    config.headers.hostName = hostName;
    config.headers.deviceId = 5;
    config.headers.appVersion = '1.0.0';
    config.headers.deviceModel = '';
    config.headers.osName = '';
    const timestamp = new Date().valueOf();
    config.headers.timestamp = timestamp;
    console.log('config1',config)
    // console.log(Qs.parse(config.data));
    var ps = Qs.parse(config.data);
    var p_arr = new Array();
    for (const p in ps) {
      p_arr.push(p+ps[p]);
    }

    p_arr.sort();
    var sign_str = Base64.encode(timestamp.toString()); 
    if (token != null && token != '') {
      config.headers.token = token;
      //sign_str = sign_str;
    }
    //385CD0C8-4FBC-4BDD-9B62-F6382170A779  --xin
    //13101FF6-DCAF-43FE-9657-8426D768884E --jiui
    sign_str = sign_str+'385CD0C8-4FBC-4BDD-9B62-F6382170A779';
    var p_str = '';
    for(var i =0;i<p_arr.length;i++){
      p_str+=p_arr[i];
    }
    sign_str = sign_str + p_str;

    config.headers.signature = md5(sign_str);


    console.log('config',config)

    return config;
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    //通过code
    const code = response.data.code;
    if (code == 0 || code == 1 || code == 2) {
      return response;
    }
    else if (code == 4 || code==-1) {
      // if(response.config.url == '/my/get_user_info'){
      //   return response;
      // }
      // if(response.config.url == '/teacher/sel_submit_audit'){
      //   return response;
      // }
      // if(response.config.url == '/partner_user/sel_submit_audit'){
      //   return response;
      // }
      localStorage.removeItem('token')
      localStorage.removeItem('setuserInfo')
      Message.error('当前登录已失效,请重新登录')
      console.log(router);
      router.replace({
          path:'/'
      })
    }
    else {
      if (response.data.msg == "当前登录已失效,请重新登录") {
        localStorage.removeItem('token')
        localStorage.removeItem('setuserInfo')
        store.removeItem("setuserInfo")
        router.replace({
            path:'/'
        })
        // localStorage.removeItem('userinfo')
        // Message.error('当前登录已失效,请重新登录')
      }
      return response;
    }
  },

  error => {
    if (loading) {
      loading.close()
    }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break
        case 404:
          Message.error('网络请求不存在')
          break
        default:

      }
    }
    else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        Message.error('请求超时！请检查网络是否正常')

      } else {
        Message.error('请求失败，请检查网络是否已连接')
      }
    }
    return Promise.reject(error)
  }
);


// get，post请求方法
export default {
  post(url, data) {
    return axios({
      method: 'post',
      url,
      data: Qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        // 'Content-Type': 'application/json'
      },
    })
  },

  filepost(url, data) {
    return axios({
      method: 'post',
      url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
      },
    })
  },

  // toqiniu(url,data) {
  //   axios({
  //     method: 'post',
  //     url,
  //     data: data,
  //     headers: {
  //       'Content-Type': 'application/octet-stream'
  //     },
  //   })
  // },

  get(url, params) {
    return axios({
      method: 'get',
      url,
      params
    })
  }
}
